import Alpine from 'alpinejs'

export default (() => {
  if (window.pageSettings.editmode) return

  let endpoints
  Alpine.data('form', function form() {
    return {
      loading: false,
      async init() {
        const form = this.$root
        const [
          FetchManager,
          ConditionalLogic,
          Endpoints
        ] = await import('js-pimcore-formbuilder').then(mod => [
          mod.FetchManager,
          mod.ConditionalLogic,
          mod.Endpoints
        ])
        const runFormHandling = () => {
          new ConditionalLogic(form, {
            actions: {
              triggerEvent: {
                onEnable: function ({ event, fields }, _, elem) {
                  [...elem].forEach(item => item.dispatchEvent(new CustomEvent(event, { bubbles: true, detail: fields })))
                },
              },
            },
          })
        }

        if (!endpoints) endpoints = await new Endpoints().getEndpoints(form.dataset.ajaxStructureUrl)

        form.addEventListener('contextchanged', e => {
          const { detail, target } = e
          const context = (detail||[]).reduce((sum, key) => {
            return {...sum, ...{ [key]: target.value }}
          }, {})

          form.querySelector('[name="_context"]').value = JSON.stringify(context)

          this.loading = true

          const body = new FormData(form)

          // need to prevent formbuilder interception
          body.set(form.name + '[_formId]', body.get(form.name + '[formId]'))
          body.delete(form.name + '[formId]')

          // do not send dynamic fields that will change
          if (body.get(form.name + '[_ajax_ignore_fields]')) {
            body.get(form.name + '[_ajax_ignore_fields]').split(',').forEach(field => {
              body.delete(form.name + `[${field}]`)
            })
          }

          fetch(endpoints.form_rerender, {
            method: form.method,
            headers: {'X-Requested-With': 'XMLHttpRequest'},
            body,
          })
          .then(response => response.json())
          .then((data) => {
            const { form: formHtml = false } = data
            if (formHtml) {
              const fragment = document.createElement('div')
              fragment.innerHTML = formHtml
              form.innerHTML = fragment.querySelector('form').innerHTML
              runFormHandling()
              form.querySelector('[name="_context"]').value = JSON.stringify(context)
            }
            this.loading = false
          })
          .catch(e => {
            console.error(e)
            this.loading = false
          })
        })

        runFormHandling()
        new FetchManager(form, {
          onGeneralError: (generalErrorMessages) => {
            console.warn('Form error: ', generalErrorMessages)
            const errorEl = document.createElement('div')
            errorEl.classList.add('error-message', 'text-red-600')
            errorEl.innerText = 'Momentan scheint es Probleme mit dem Server zu geben. Bitte versuchen Sie es erneut oder melden Sie sich bei dem Administrator.'
            form.after(errorEl)
          },
          onSuccess(messages, redirect) {
            let target
            ;[...form.querySelectorAll('.error-message')].forEach(error => error.remove())
            ;[...form.parentElement.querySelectorAll('form ~ .error-message')].forEach(error => error.remove())

            if (redirect) {
              if (redirect.substring(0, 1) === '/') target = new URL(window.location.origin + redirect)
              else target = new URL(redirect)
              window.location = target
            }
            else if (messages.length) {
              form.dispatchEvent(new CustomEvent('formsuccess', { bubbles: true }))
              form.outerHTML = messages.map(({type, message}) => {
                if (type !== 'success') return ''
                return message
              }).join('\n')
            }
          },
          elementTransformer: {
            addValidationMessage: (form, field, messages) => {
              form.classList.add('error-form')
              appendErrors(field, messages)
            },
            removeFormValidations: (form) => {
              form.classList.remove('error-form')
              form
                .querySelectorAll('.field-error')
                .forEach((el) => el.classList.remove('field-error', 'border-red-600'))
              form
                .querySelectorAll('.error-message')
                .forEach((el) => el.remove())
            },
          },
        }, endpoints)
      },
    }
  })
})()


function appendErrors(field, messages) {
  let additionalClasses = []
  let wrapper = field.closest('.form-row')
  if (!wrapper) {
    wrapper = field.closest('form')
    additionalClasses = ['px-2.5', 'pt-2.5']
  }
  wrapper.classList.add('field-error', 'border-red-600')
  ;[...messages].forEach((message) => {
    const errorEl = document.createElement('div')
    errorEl.classList.add('error-message', 'text-red-600')
    if (additionalClasses.length) errorEl.classList.add(...additionalClasses)
    errorEl.innerText = message
    wrapper.after(errorEl)
  })
}