import Alpine from 'alpinejs'

const PLAYER_SETTINGS_GENERAL = {
  autoplay: true,
  muted: true,
  controls: [],
  vimeo: {
    dnt: true,
  },
  youtube: {
    noCookie: true,
    customControls: false,
    playerVars: {
      origin: window.location.origin,
    },
  },
}

export default (() => {
  if (window.pageSettings.editmode) return

  let Plyr

  Alpine.data('videoLightbox', function videoLightbox() {
    let player

    return {
      instance: null,
      ratio: 9 / 16,
      loaded: false,
      opened: false,
      playing: false,
      async init() {
        if (!Plyr) {
          [Plyr] = await Promise.all([
            import('plyr').then((mod) => mod.default).catch(e => console.log(e)),
            import('../../styles/containers/video.scss'),
          ])
        }

        const setRatioToParent = () => {
          this.ratio = this.instance.ratio.split(':').reverse().join(' / ')
        }

        player = this.$refs['player']

        // needed for YT
        document.addEventListener('ready', (e) => {
          const { target } = e
          if (!this.instance || (target !== player && !player.contains(target))) return

          this.instance.play()
          this.instance.muted = false
          this.loaded = true
          this.playing = true
        })

        document.addEventListener('playing', (e) => {
          const { target } = e
          if (!this.instance || (target !== player && !player.contains(target))) return

          this.instance.muted = false
          this.loaded = true
          this.playing = true

          if (this.instance.ratio) {
            setRatioToParent()
          } else {
            setTimeout(() => {
              // sometimes ratio is not available at start
              // this is why the timeout is needed
              if (this.instance.ratio) {
                setRatioToParent()
              }
            }, 10)
          }
        })
      },
      remove() {
        Alpine.store('helpers').toggleOverflow(true)
        this.opened = false
        this.playing = false
        this.loaded = false
        if (this.instance) {
          this.instance.autoplay = false // maybe not needed
          this.instance.source = {} // maybe not needed
          this.instance.destroy()
        }
        this.$refs['player'].innerHTML = ''
      },
      async toggle() {
        try {
          await this.instance.togglePlay()
        } catch (error) {
          console.log(error)
        }
        this.playing = !this.playing
      },
      start() {
        this.instance.play()
        this.instance.muted = false
        this.loaded = true
        this.playing = true
      },
      open({ id, provider }) {
        this.opened = true
        
        Alpine.store('helpers').toggleOverflow()

        player.innerHTML = `<div x-ref="child" data-plyr-embed-id="${id}" data-plyr-provider="${provider}"></div>`

        this.$nextTick(() => {
          this.instance = new Plyr(this.$refs['child'], PLAYER_SETTINGS_GENERAL)
          player.plyr = this.instance

          this.instance.on('ended', () => {
            this.playing = false
          })

          // youtube not working properly
          if (provider === 'youtube') {
            setTimeout(() => {
              this.start()
            }, 10)
          }
          else {
            this.instance.once('ready', () => this.start())
          }
        })
      }
    }
  })
})()